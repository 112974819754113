import { IoMdDocument } from 'react-icons/io'
import { FC } from 'react';

type EmptyTableProps = {
    isSearch: boolean;
    title: string;
}

const EmptyTable: FC<EmptyTableProps> = ({ isSearch, title }) => {
    return (
        <div className="flex items-center gap-4 flex-col py-10 w-full border-solid border-[1px] rounded-2xl">
            <IoMdDocument color={"#E5E7EB"} size={60} />
            <p className='text-[18px] leading-[24px] font-medium'>
                {isSearch ? 'За вашим запитом нічого не знайдено' : title}
            </p>
        </div>
    )
}

export default EmptyTable