import { FC, useEffect, useState } from "react";
import { FaCheckSquare } from "react-icons/fa";
import { IoMdClose } from "react-icons/io"

import { useEditAutomobileMutation, useGetAutomobileByIdQuery } from "../api/appApi";

import Modal from "./Modal"

type EditCarModalProps = {
    onClose: () => void;
    id: string;
}

const EditCarModal: FC<EditCarModalProps> = ({ onClose, id }) => {
    const [licensePlate, setLicensePlate] = useState<string>("")
    const [whiteList, setWhiteList] = useState<boolean>(false)
    const [brand, setBrand] = useState<string>("")
    const [model, setModel] = useState<string>("")

    const { data } = useGetAutomobileByIdQuery(id)
    const [editCar] = useEditAutomobileMutation()

    const handleEditCar = () => {
        editCar({ id, data: { license_plate: licensePlate, make: `${brand} ${model}`, white_listed: whiteList } })
        onClose()
    }

    useEffect(() => {
        if (data) {
            setLicensePlate(data.license_plate)
            setWhiteList(data.white_listed)
            setBrand(data.make.split(' ')[0])
            setModel(data.make.split(' ')[1])
        }
    }, [data])

    return (
        <Modal bodyStyles={{ borderRadius: '12px' }} customBodyClasses="bg-textColor max-w-[500px]" onClose={onClose}>
            <div className="flex flex-col gap-4">
                <h5 className="text-[22px] leading-[28px] font-semibold flex items-center justify-between">
                    Редагувати автомобіль
                    <IoMdClose className="cursor-pointer" onClick={onClose} size={28} />
                </h5>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Номерний знак
                    </p>
                    <input
                        className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                        onChange={(e) => setLicensePlate(e.target.value)}
                        placeholder="Номерний знак"
                        value={licensePlate}
                        type="text"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Марка
                    </p>
                    <input
                        className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                        onChange={(e) => setBrand(e.target.value)}
                        placeholder="Марка"
                        value={brand}
                        type="text"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Модель
                    </p>
                    <input
                        className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                        onChange={(e) => setModel(e.target.value)}
                        placeholder="Модель"
                        value={model}
                        type="text"
                    />
                </div>
                <div className="flex items-center gap-2">
                    {whiteList ? (
                        <FaCheckSquare
                            className="cursor-pointer"
                            color={"#4440c6"}
                            onClick={() => setWhiteList(false)}
                            size={24}
                        />
                    ) : (
                        <div
                            onClick={() => setWhiteList(true)}
                            className="w-[24px] h-[24px] rounded-[4px] border-solid border-[1px] border-placeholderColor"
                        ></div>
                    )}
                    <p className="text-[16px] leading-[22px] text-darkText font-medium">
                        В білому списоку
                    </p>
                </div>
                <div className="flex items-center gap-2 mt-3">
                    <button onClick={handleEditCar} className="h-[56px] rounded-lg bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300 w-1/2 font-semibold text-[18px] leading-[26px]">
                        Зберегти зміни
                    </button>
                    <button onClick={onClose} className="h-[56px] rounded-lg border-solid border-[1px] border-placeholderColor w-1/2 font-semibold text-[18px] leading-[26px]">
                        Скасувати
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default EditCarModal