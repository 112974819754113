import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../redux/index';

const BASE_URL = `https://parking-management-system.sp-lutsk.com/api/`

export const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).app.user?.accessToken;

        if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`);
        }

        return headers;
    },
});