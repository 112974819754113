import { useEffect, useState } from "react"

import { ITEMS_PER_PAGE } from "../utils/constants";

type UsePaginationResponse = {
    page: number;
    setPage: (page: number) => void;
    totalPages: number;
    setTotalPages: (totalPages: number) => void;
    itemsPerPage: number;
    setItemsPerPage: (itemsPerPage: number) => void;
}

const usePagination = (customItemsPerPage?: number): UsePaginationResponse => {
    const [itemsPerPage, setItemsPerPage] = useState(customItemsPerPage ? customItemsPerPage : ITEMS_PER_PAGE)
    const [totalPages, setTotalPages] = useState(1)
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (page > totalPages) setPage(totalPages > 0 ? totalPages : 1)
    }, [page, totalPages])

    const value: UsePaginationResponse = {
        page,
        setPage,
        totalPages,
        setTotalPages,
        itemsPerPage,
        setItemsPerPage
    }

    return value
}

export default usePagination