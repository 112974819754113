import { FC, useEffect, useState } from "react";
import { FaCheckSquare } from "react-icons/fa";
import { IoMdClose } from "react-icons/io"

import { useAppSelector } from "../redux/hooks";
import {
    useEditParkingMutation,
    useGetManagersListQuery,
    useGetParkingByIdQuery
} from "../api/appApi";

import Modal from "./Modal"

type EditParkingModalProps = {
    onClose: () => void;
    id: string;
}

const EditParkingModal: FC<EditParkingModalProps> = ({ onClose, id }) => {
    const [monthlySubscription, setMonthlySubscription] = useState<number>(0)
    const [currentManagers, setCurrentManagers] = useState<string[]>([])
    const [geolocation, setGeolocation] = useState<string>("")
    const [hourlyRate, setHourlyRate] = useState<number>(0)
    const [nightRate, setNightRate] = useState<number>(0)
    const [capacity, setCapacity] = useState<number>(0)
    const [address, setAddress] = useState<string>("")
    const [name, setName] = useState<string>("")
    const userInfo = useAppSelector(state => state.app.userInfo)

    const { data: managers } = useGetManagersListQuery(undefined, { skip: userInfo?.role !== 'ADMIN' })
    const { data } = useGetParkingByIdQuery(id)
    const [editParking] = useEditParkingMutation()

    const handleEditParking = () => {
        editParking({
            id,
            data: {
                geolocation,
                hourly_rate: String(hourlyRate),
                capacity,
                address,
                name,
                night_rate: String(nightRate),
                month_subscription: String(monthlySubscription),
                managers: currentManagers
            }
        })
        onClose()
    }

    const handleSelectManager = (managerId: string) => {
        if (currentManagers.includes(managerId)) {
            const filteredManagers = currentManagers.filter(manager => manager !== managerId)

            setCurrentManagers(filteredManagers)
        } else {
            const newManagers = [...currentManagers, managerId]

            setCurrentManagers(newManagers)
        }
    }

    useEffect(() => {
        if (data) {
            setMonthlySubscription(Number(data.month_subscription))
            setHourlyRate(Number(data.hourly_rate))
            setNightRate(Number(data.night_rate))
            setGeolocation(data.geolocation)
            setCapacity(data.capacity)
            setAddress(data.address)
            setName(data.name)

            const filteredManagers = data.managers.filter(manager => managers?.results.find(m => m.id === manager))

            setCurrentManagers(filteredManagers)
        }
    }, [data, managers])

    return (
        <Modal bodyStyles={{ borderRadius: '12px' }} customBodyClasses="bg-textColor max-w-[500px]" onClose={onClose}>
            <div className="flex flex-col gap-4">
                <h5 className="text-[22px] leading-[28px] font-semibold flex items-center justify-between">
                    Редагувати парковку
                    <IoMdClose className="cursor-pointer" onClick={onClose} size={28} />
                </h5>
                <div className="flex flex-col gap-4 w-full max-h-[calc(90vh-140px)] overflow-y-auto">
                    <div className="flex flex-col gap-1">
                        <p className="text-[16px] leading-[22px] text-darkText">
                            Назва парковки
                        </p>
                        <input
                            className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Назва парковки"
                            value={name}
                            type="text"
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-[16px] leading-[22px] text-darkText">
                            Адреса парковки
                        </p>
                        <input
                            className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="Адреса парковки"
                            value={address}
                            type="text"
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-[16px] leading-[22px] text-darkText">
                            Місткість
                        </p>
                        <input
                            className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                            onChange={(e) => setCapacity(Number(e.target.value))}
                            placeholder="Місткість"
                            value={capacity}
                            type="number"
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-[16px] leading-[22px] text-darkText">
                            Геолокація
                        </p>
                        <input
                            className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                            onChange={(e) => setGeolocation(e.target.value)}
                            placeholder="50.7460686,25.3171832"
                            value={geolocation}
                            type="string"
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-[16px] leading-[22px] text-darkText">
                            Погодинна ставка
                        </p>
                        <input
                            className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                            onChange={(e) => setHourlyRate(Number(e.target.value))}
                            placeholder="10"
                            value={hourlyRate}
                            type="number"
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-[16px] leading-[22px] text-darkText">
                            Нічна ставка
                        </p>
                        <input
                            className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                            onChange={(e) => setNightRate(Number(e.target.value))}
                            placeholder="10"
                            value={nightRate}
                            type="number"
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-[16px] leading-[22px] text-darkText">
                            Підписка на місяць
                        </p>
                        <input
                            className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                            onChange={(e) => setMonthlySubscription(Number(e.target.value))}
                            placeholder="10"
                            value={monthlySubscription}
                            type="number"
                        />
                    </div>
                    {userInfo?.role === 'ADMIN' && (
                        <div className="flex flex-col gap-1">
                            <p className="text-[16px] leading-[22px] text-darkText">
                                Менеджери
                            </p>
                            <div className="flex flex-col gap-3 border-solid border-[1px] border-borderColor rounded-lg p-3">
                                {managers?.results.map(manager => (
                                    <div key={manager.id} className="flex items-center gap-2">
                                        {currentManagers.includes(manager.id) ? (
                                            <FaCheckSquare
                                                className="cursor-pointer"
                                                color={"#4440c6"}
                                                onClick={() => handleSelectManager(manager.id)}
                                                size={24}
                                            />
                                        ) : (
                                            <div
                                                onClick={() => handleSelectManager(manager.id)}
                                                className="w-[24px] h-[24px] rounded-[4px] border-solid border-[1px] border-placeholderColor cursor-pointer"
                                            ></div>
                                        )}
                                        <p className="text-[16px] leading-[22px] text-darkText font-medium">
                                            {manager.email}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex items-center gap-2 mt-3">
                    <button onClick={handleEditParking} className="h-[56px] rounded-lg bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300 w-1/2 font-semibold text-[18px] leading-[26px]">
                        Зберегти зміни
                    </button>
                    <button onClick={onClose} className="h-[56px] rounded-lg border-solid border-[1px] border-placeholderColor w-1/2 font-semibold text-[18px] leading-[26px]">
                        Скасувати
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default EditParkingModal