import { Line } from "react-chartjs-2"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartOptions,
} from 'chart.js';
import { FC } from "react";

import { useGetGraphStatsQuery } from "../api/appApi";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

type GraphProps = {
    selected: string
}

const Graph: FC<GraphProps> = ({ selected }) => {
    const { data: graphData } = useGetGraphStatsQuery(selected)

    const options: ChartOptions<"line"> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                intersect: false,
                borderColor: '#F8E74B',
                backgroundColor: '#F8E74B',
                titleColor: '#000000',
                displayColors: false,
                titleFont: {
                    size: 28,
                    weight: 700,
                    lineHeight: 0.9,
                },
                bodyColor: '#000000',
                bodyFont: {
                    size: 16,
                    weight: 600,
                },
                footerColor: '#3f444d',
                footerFont: {
                    size: 16,
                    weight: 400,
                },
                borderWidth: 1,
                callbacks: {
                    title: function (context) {
                        return `${context[0]?.dataset.label === 'Зароблено' ? '₴' : ''}${context[0]?.formattedValue}`
                    },
                    label: function (context) {
                        return `${context.dataset.label}`
                    },
                    beforeFooter: function (context) {
                        return `${context[0]?.label}`;
                    }
                },
            },
        },
        elements: {
            point: {
                radius: 3,
                hoverRadius: 7,
                pointStyle: 'circle',
                backgroundColor: '#ffffff',
                borderColor: '#F4DE0D',
                hoverBackgroundColor: '#F4DE0D',
                hoverBorderColor: '#F4DE0D',
                borderWidth: 2,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: true,
                    color: '#3f444d',
                    font: {
                        weight: 600,
                    }
                },
                border: {
                    display: false,
                },
                offset: false,
            },
            y: {
                grid: {
                    display: true,
                },
                ticks: {
                    display: false,
                },
                border: {
                    display: false,
                },
                min: 0,
                offset: false,
            },
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        }
    }

    const datasets = [
        {
            label: 'Нових користувачів',
            data: Object.values(graphData?.users ? graphData?.users : {}),
            borderColor: '#F4DE0D',
            lineTension: 0.5,
            backgroundColor: 'rgba(244, 222, 13, 0.1)',
            fill: true,
        },
        {
            label: 'Зароблено',
            data: Object.values(graphData?.earnings ? graphData?.earnings : {}),
            borderColor: '#F4DE0D',
            lineTension: 0.5,
            backgroundColor: 'rgba(244, 222, 13, 0.1)',
            fill: true,
        },
        {
            label: 'Сесій парковок',
            data: Object.values(graphData?.parking_sessions ? graphData?.parking_sessions : {}),
            borderColor: '#F4DE0D',
            lineTension: 0.5,
            backgroundColor: 'rgba(244, 222, 13, 0.1)',
            fill: true,
        }
    ]

    const data = {
        labels: Object.keys(graphData?.users ? graphData?.users : {}).map((key) => key),
        datasets: datasets
    }

    return (
        <div className="flex flex-col gap-4 border-solid border-[1px] border-secondaryBackgroundColor p-4 rounded-xl">
            <h5 className="text-[22px] leading-[28px] font-semibold">
                Статистика по днях
            </h5>
            <div className="h-[400px]">
                <Line data={data} options={options} />
            </div>
        </div>
    )
}

export default Graph