import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit'

import rootReducers from './rootReducer';
import { appApi } from '../api/appApi';

export const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat([
        appApi.middleware,
    ]),
})

export type RootState = ReturnType<typeof store.getState>
export type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;
