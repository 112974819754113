import { combineReducers } from "@reduxjs/toolkit"

import { appApi } from "../api/appApi"
import appReducer from './appSlice'

const rootReducers = combineReducers({
    app: appReducer,
    [appApi.reducerPath]: appApi.reducer,
})

export default rootReducers
