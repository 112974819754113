import { FC, useState } from "react";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";

import { TCar, TParking, TParkingStats, TUser } from "../../utils/types";
import { convertDateToLocalFormat } from "../../utils/functions";
import { EditUserModalProps } from "../../modals/EditUserModal";
import { COLUMN_TO_TITLE } from "../../utils/constants";

import ConfirmDeleteModal from "../../modals/ConfirmDeleteModal";

type TableTypes = "users" | "parkings" | "cars" | "payments" | "parkings_stats";

type TableDataType<T extends TableTypes> =
    T extends "users" ? TUser :
    T extends "parkings" ? TParking :
    T extends "cars" ? TCar :
    T extends "payments" ? TCar :
    T extends "parkings_stats" ? TParkingStats & { id: string } :
    never;

type TableProps<T extends TableTypes> = {
    type: T;
    data: TableDataType<T>[];
    columns: string[];
    deleteAction?: boolean;
    editAction?: boolean;
    deleteEndpoint?: (id: string) => void;
    EditModal?: FC<EditUserModalProps>;
    beforeValue?: {
        [key: string]: string;
    },
    afterValue?: {
        [key: string]: string;
    }
};

const Table = <T extends TableTypes>({
    data,
    columns,
    deleteAction = false,
    editAction = false,
    deleteEndpoint,
    EditModal,
    beforeValue,
    afterValue
}: TableProps<T>) => {
    const [deleteId, setDeleteId] = useState<string>("")
    const [editId, setEditId] = useState<string>("")

    const handleDeleteConfirmed = () => {
        if (deleteEndpoint) {
            deleteEndpoint(deleteId)
        }
        setDeleteId("")
    }

    const handleDelete = (id: string) => {
        if (deleteEndpoint) {
            setDeleteId(id)
        }
    }

    return (
        <div className="bg-white w-full flex flex-col rounded-xl overflow-hidden shadow-100">
            <div
                style={{ gridTemplateColumns: `repeat(${columns.length}, 1fr) ${deleteAction || editAction ? '90px' : ''}`, gridTemplateAreas: `'${columns.join(' ')}'` }}
                className='grid w-full bg-buttonColor h-[50px] items-center'>
                {Object.keys(data[0]).map((key, index) => {
                    if (columns.includes(key)) {
                        return (
                            <div
                                key={index}
                                style={{ gridArea: key }}
                                className="py-2 px-4 text-darkColor font-semibold truncate"
                            >
                                {COLUMN_TO_TITLE[key as keyof typeof COLUMN_TO_TITLE]}
                            </div>
                        )
                    } else {
                        return null
                    }
                })}
                {(deleteAction || editAction) && (
                    <div className="py-2 text-darkColor font-semibold truncate">
                        Дії
                    </div>
                )}
            </div>
            <div className="flex flex-col w-full">
                {data.map((row, index) => (
                    <div
                        key={index}
                        style={{
                            gridTemplateColumns: `repeat(${columns.length}, 1fr) ${deleteAction || editAction ? '90px' : ''}`,
                            gridTemplateAreas: `'${columns.join(' ')}'`
                        }}
                        className="grid w-full h-[50px] bg-dashboardTableColor items-center border-b-[1px] border-x-[1px] border-whiteButtonHoverColor last:rounded-b-xl"
                    >
                        {
                            Object.keys(row).map((key, index) => {
                                if (columns.includes(key)) {
                                    if (typeof (row[key as keyof TableDataType<T>]) === 'boolean') {
                                        return (
                                            <div key={index} style={{ gridArea: key }} className="py-2 px-4 text-textDark truncate">
                                                {row[key as keyof TableDataType<T>] ? 'Так' : 'Ні'}
                                            </div>
                                        )
                                    } else if (key === 'payment_date') {
                                        return (
                                            <div key={index} style={{ gridArea: key }} className="py-2 px-4 text-textDark truncate">
                                                {
                                                    row[key as keyof TableDataType<T>]
                                                        ? convertDateToLocalFormat(row[key as keyof TableDataType<T>] as string)
                                                        : '-'
                                                }
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={index} style={{ gridArea: key }} className="py-2 px-4 text-textDark truncate">
                                                {row[key as keyof TableDataType<T>] ? `${beforeValue && beforeValue[key] ? beforeValue[key] : ''}${String(row[key as keyof TableDataType<T>])}` : '-'}{afterValue && afterValue[key] ? afterValue[key] : ''}
                                            </div>
                                        )
                                    }
                                } else {
                                    return null
                                }
                            })
                        }
                        {(deleteAction || editAction) && (
                            <div className="flex items-center justify-start pe-4 gap-1">
                                {editAction && (
                                    <button onClick={() => setEditId(row.id)} className="min-w-[36px] w-[36px] flex items-center justify-center h-[36px] rounded-lg bg-secondaryBackgroundColor hover:bg-secondaryBackgroundHoverColor transition-colors duration-300 text-textColor">
                                        <MdOutlineEdit color="#000000" size={22} />
                                    </button>
                                )}
                                {deleteAction && (
                                    <button onClick={() => handleDelete(row.id)} className="min-w-[36px] w-[36px] flex items-center justify-center h-[36px] rounded-lg bg-errorColor hover:bg-errorColorHover transition-colors duration-300 text-textColor">
                                        <MdDeleteOutline size={22} />
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {deleteId && (
                <ConfirmDeleteModal
                    onClose={() => setDeleteId("")}
                    confirmDelete={handleDeleteConfirmed}
                />
            )}
            {editId && EditModal && (
                <EditModal id={editId} onClose={() => setEditId("")} />
            )}
        </div>
    )
}

export default Table