import { Route, Routes } from "react-router-dom";

import { useAppSelector } from "./redux/hooks";

import NoPermissions from "./pages/NoPermissions";
import Dashboard from "./pages/Dashboard";
import Auth from "./pages/Auth";

function App() {
  const userInfo = useAppSelector((state) => state.app.userInfo);

  return (
    <div className="w-full min-h-screen bg-textColor">
      {userInfo ? (
        <>
          {userInfo?.role === 'USER' ? (
            <Routes>
              <Route path="*" element={<NoPermissions />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="*" element={<Dashboard />}></Route>
            </Routes>
          )}
        </>
      ) : (
        <Routes>
          <Route path='*' element={<Auth />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
