import { createApi } from "@reduxjs/toolkit/query/react";

import {
    TCar,
    TGlobalStats,
    TGraphData,
    TManager,
    TPaginationResponse,
    TParking,
    TParkingEventLog,
    TParkingStats,
    TSystemEventLog,
    TUser,
    TUserInfo
} from "../utils/types";
import { baseQuery } from "./config";
import { FILTER_TO_KEY } from "../utils/constants";

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery: baseQuery,
    tagTypes: ['UsersList', 'ParkingsList', 'CarsList', 'SystemEventLogs', 'Automobile', 'User', 'Parking', 'ManagersList'],
    endpoints: builder => ({
        getUserInfo: builder.query<TUserInfo, void>({
            query: () => {
                return {
                    url: `users/me`,
                    method: 'GET',
                }
            }
        }),
        getUsersList: builder.query<TPaginationResponse<TUser>, { page: number, itemsPerPage: number, search: string }>({
            query: ({ page, itemsPerPage, search }) => {
                return {
                    url: `users/admin/users?page=${page}&page_size=${itemsPerPage}${search ? `&search=${search}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['UsersList']
        }),
        getParkingsList: builder.query<TPaginationResponse<TParking>, { page: number, itemsPerPage: number, search: string }>({
            query: ({ page, itemsPerPage, search }) => {
                return {
                    url: `parking/admin/parking-lot?page=${page}&page_size=${itemsPerPage}${search ? `&search=${search}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['ParkingsList']
        }),
        getCarsList: builder.query<TPaginationResponse<TCar>, { page: number, itemsPerPage: number, search: string }>({
            query: ({ page, itemsPerPage, search }) => {
                return {
                    url: `parking/admin/automobile?page=${page}&page_size=${itemsPerPage}${search ? `&search=${search}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['CarsList']
        }),
        getPaymentsList: builder.query<TPaginationResponse<TCar>, { page: number, itemsPerPage: number, search: string }>({
            query: ({ page, itemsPerPage, search }) => {
                return {
                    url: `payments/admin?page=${page}&page_size=${itemsPerPage}${search ? `&search=${search}` : ''}`,
                    method: 'GET',
                }
            }
        }),
        deleteUser: builder.mutation<void, string>({
            query: (id) => {
                return {
                    url: `users/admin/users/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['UsersList', 'SystemEventLogs']
        }),
        getUserById: builder.query<TUser, string>({
            query: (id) => {
                return {
                    url: `users/admin/users/${id}`,
                    method: 'GET',
                }
            },
            providesTags: ['User']
        }),
        editUser: builder.mutation<void, { id: string, data: Partial<TUser> }>({
            query: ({ id, data }) => {
                return {
                    url: `users/admin/users/${id}/`,
                    method: 'PATCH',
                    body: data
                }
            },
            invalidatesTags: ['UsersList', 'SystemEventLogs', 'User']
        }),
        deleteCar: builder.mutation<void, string>({
            query: (id) => {
                return {
                    url: `parking/admin/automobile/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['CarsList', 'SystemEventLogs']
        }),
        getAutomobileById: builder.query<TCar, string>({
            query: (id) => {
                return {
                    url: `parking/admin/automobile/${id}`,
                    method: 'GET',
                }
            },
            providesTags: ['Automobile']
        }),
        editAutomobile: builder.mutation<void, { id: string, data: Partial<TCar> }>({
            query: ({ id, data }) => {
                return {
                    url: `parking/admin/automobile/${id}/`,
                    method: 'PATCH',
                    body: data
                }
            },
            invalidatesTags: ['CarsList', 'SystemEventLogs', 'Automobile']
        }),
        deleteParking: builder.mutation<void, string>({
            query: (id) => {
                return {
                    url: `parking/admin/parking-lot/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['ParkingsList', 'SystemEventLogs']
        }),
        getParkingById: builder.query<TParking, string>({
            query: (id) => {
                return {
                    url: `parking/admin/parking-lot/${id}`,
                    method: 'GET',
                }
            },
            providesTags: (result) => [{ type: 'Parking', id: result?.id }]
        }),
        editParking: builder.mutation<void, { id: string, data: Partial<TParking> }>({
            query: ({ id, data }) => {
                return {
                    url: `parking/admin/parking-lot/${id}/`,
                    method: 'PATCH',
                    body: data
                }
            },
            invalidatesTags: ['ParkingsList', 'SystemEventLogs', 'Parking']
        }),
        addParking: builder.mutation<void, Partial<TParking>>({
            query: (data) => {
                return {
                    url: `parking/admin/parking-lot/`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['ParkingsList', 'SystemEventLogs']
        }),
        getDashboardGlobalStats: builder.query<TGlobalStats, string>({
            query: (filter) => {
                const selectedFilter = FILTER_TO_KEY[filter as keyof typeof FILTER_TO_KEY]

                return {
                    url: `dashboard/admin/global-stats${selectedFilter ? `?days_range=${selectedFilter}` : ''}`,
                    method: 'GET',
                }
            }
        }),
        getParkingsStats: builder.query<TParkingStats[], string>({
            query: (filter) => {
                const selectedFilter = FILTER_TO_KEY[filter as keyof typeof FILTER_TO_KEY]

                return {
                    url: `dashboard/admin/parking-lot-stats${selectedFilter ? `?days_range=${selectedFilter}` : ''}`,
                    method: 'GET',
                }
            }
        }),
        getParkingEventLogs: builder.query<TPaginationResponse<TParkingEventLog>, { page: number, itemsPerPage: number, search: string }>({
            query: ({ page, itemsPerPage, search }) => {
                return {
                    url: `eventlog/admin/parking-record?page=${page}&page_size=${itemsPerPage}${search ? `&search=${search}` : ''}`,
                    method: 'GET',
                }
            }
        }),
        getSystemEventLogs: builder.query<TPaginationResponse<TSystemEventLog>, { page: number, itemsPerPage: number, search: string }>({
            query: ({ page, itemsPerPage, search }) => {
                return {
                    url: `eventlog/admin/system-change-record?page=${page}&page_size=${itemsPerPage}${search ? `&search=${search}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['SystemEventLogs']
        }),
        getGraphStats: builder.query<TGraphData, string>({
            query: (filter) => {
                const selectedFilter = FILTER_TO_KEY[filter as keyof typeof FILTER_TO_KEY]

                return {
                    url: `dashboard/admin/graph-stats${selectedFilter ? `?days_range=${selectedFilter}` : ''}`,
                    method: 'GET',
                }
            }
        }),
        getManagersList: builder.query<TPaginationResponse<TManager>, void>({
            query: () => {
                return {
                    url: `users/admin/managers?page=1&page_size=10000`,
                    method: 'GET',
                }
            },
            providesTags: ['ManagersList']
        })
    })
})

export const {
    useGetUserInfoQuery,
    useGetUsersListQuery,
    useGetParkingsListQuery,
    useGetCarsListQuery,
    useGetPaymentsListQuery,
    useDeleteUserMutation,
    useGetUserByIdQuery,
    useEditUserMutation,
    useDeleteCarMutation,
    useGetAutomobileByIdQuery,
    useEditAutomobileMutation,
    useDeleteParkingMutation,
    useGetParkingByIdQuery,
    useEditParkingMutation,
    useAddParkingMutation,
    useGetDashboardGlobalStatsQuery,
    useGetParkingsStatsQuery,
    useGetParkingEventLogsQuery,
    useGetSystemEventLogsQuery,
    useGetGraphStatsQuery,
    useGetManagersListQuery
} = appApi