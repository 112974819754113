import { FaPlus } from "react-icons/fa6"
import { FC, useEffect, useState } from "react"

type TitleProps = {
    title: string;
    setPage?: (page: number) => void;
    setSearch?: (search: string) => void;
    addAction?: boolean;
    addActionHandler?: () => void;
    addTitle?: string;
}

const Title: FC<TitleProps> = ({ title, setPage, setSearch, addAction = false, addActionHandler, addTitle }) => {
    const [inputValue, setInputValue] = useState<string>("")

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (setSearch && setPage) {
                setSearch(inputValue)
                setPage(1)
            }
        }, 700)

        return () => clearTimeout(timeout)
    }, [setSearch, setPage, inputValue])

    return (
        <div className="flex items-center justify-between">
            <h5 className="text-[22px] leading-[28px] font-semibold text-backgroundColor">
                {title}
            </h5>
            {setSearch && (
                <div className="flex items-center gap-2">
                    <div className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4 w-[250px]">
                        <input
                            onChange={(e) => setInputValue(e.target.value)}
                            className="h-full w-full text-base"
                            placeholder="Пошук"
                            value={inputValue}
                            type="text"
                        />
                    </div>
                    {addAction && (
                        <div onClick={addActionHandler} className="px-4 h-[44px] flex items-center justify-center rounded-lg bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300 cursor-pointer gap-2 text-base font-semibold select-none">
                            <FaPlus size={20} />
                            {addTitle}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Title