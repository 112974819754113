import { MdDeleteOutline } from "react-icons/md";
import { FC } from "react";

import Modal from "./Modal"

type ConfirmDeleteModalProps = {
    onClose: () => void;
    confirmDelete: () => void;
}

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({ onClose, confirmDelete }) => {
    return (
        <Modal bodyStyles={{ borderRadius: '12px' }} customBodyClasses="bg-textColor max-w-[500px]" onClose={onClose}>
            <div className="flex flex-col items-center w-full gap-4">
                <MdDeleteOutline color="#ef4444" size={90} />
                <h5 className="text-[22px] leading-[28px] font-semibold text-darkColor text-center">
                    Ви впевнені, що хочете виконати видалення?
                </h5>
                <div className="flex items-center gap-4 w-full mt-2">
                    <button onClick={confirmDelete} className="w-1/2 h-[56px] bg-errorColor hover:bg-errorColorHover transition-colors duration-300 rounded-xl text-textColor text-[18px] leading-[26px] font-semibold">
                        Видалити
                    </button>
                    <button onClick={onClose} className="w-1/2 h-[56px] border-solid border-[1px] border-placeholderColor rounded-xl text-[18px] leading-[26px] text-darkColor font-semibold">
                        Скасувати
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmDeleteModal