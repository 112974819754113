import { FaCarSide, FaMoneyCheckAlt, FaParking, FaUsers } from "react-icons/fa"
import { FC } from "react"

import { useGetDashboardGlobalStatsQuery } from "../api/appApi"

type TotalStatsProps = {
    selected: string
}

const TotalStats: FC<TotalStatsProps> = ({ selected }) => {
    const { data } = useGetDashboardGlobalStatsQuery(selected)

    return (
        <div className="grid grid-cols-4 w-full gap-4">
            <div className="flex flex-col p-6 bg-buttonColor rounded-xl gap-2">
                <FaUsers size={35} />
                <p className="font-semibold text-[18px] leading-[22px]">
                    Користувачів
                </p>
                <h5 className="text-[26px] leading-[26px] font-extrabold">
                    {data?.total_users}
                </h5>
            </div>
            <div className="flex flex-col p-6 bg-buttonColor rounded-xl gap-2">
                <FaParking size={35} />
                <p className="font-semibold text-[18px] leading-[22px]">
                    Відвідувань
                </p>
                <h5 className="text-[26px] leading-[26px] font-extrabold">
                    {data?.total_parking_sessions}
                </h5>
            </div>
            <div className="flex flex-col p-6 bg-buttonColor rounded-xl gap-2">
                <FaMoneyCheckAlt size={35} />
                <p className="font-semibold text-[18px] leading-[22px]">
                    Зароблено
                </p>
                <h5 className="text-[26px] leading-[26px] font-extrabold">
                    ₴{data?.total_earnings ? data?.total_earnings.toFixed(2) : 0}
                </h5>
            </div>
            <div className="flex flex-col p-6 bg-buttonColor rounded-xl gap-2">
                <FaCarSide size={35} />
                <p className="font-semibold text-[18px] leading-[22px]">
                    Автомобілів
                </p>
                <h5 className="text-[26px] leading-[26px] font-extrabold">
                    {data?.total_automobiles}
                </h5>
            </div>
        </div>
    )
}

export default TotalStats