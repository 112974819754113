import { createSlice } from '@reduxjs/toolkit'

import { FirebaseUser, TUserInfo } from '../utils/types'
import { appApi } from '../api/appApi'

type TAppState = {
    user: FirebaseUser | null,
    userInfo: TUserInfo | null,
}

const initialState: TAppState = {
    user: null,
    userInfo: null,
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        signOut: (state) => {
            state.user = null
            state.userInfo = null
        },
        setUser: (state, { payload }: { payload: FirebaseUser | null }) => {
            state.user = payload
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(appApi.endpoints.getUserInfo.matchFulfilled,
            (state, { payload }) => {
                state.userInfo = payload
            }
        )
    }
})

export const { signOut, setUser } = appSlice.actions

export default appSlice.reducer