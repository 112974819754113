import { User, onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { FC, createContext, useContext, useEffect } from "react";

import { appleProvider, auth, googleProvider } from "../firebase/index";
import { setUser, signOut as signOutState } from "../redux/appSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { appApi, useGetUserInfoQuery } from "../api/appApi";

type AuthProviderProps = {
    children: React.ReactNode;
}

type AuthContextType = {
    googleAuth: () => Promise<User | null>,
    appleAuth: () => Promise<User | null>,
    signOutUser: () => void
} | null;

const AuthContext = createContext<AuthContextType>(null);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const user = useAppSelector((state) => state.app.user)

    const dispatch = useAppDispatch()

    useGetUserInfoQuery(undefined, { skip: !user })

    const signOutUser = async () => {
        try {
            await signOut(auth)
            dispatch(signOutState())
            dispatch(appApi.util.resetApiState())
        } catch (e) {
            console.error(e)
        }
    }

    const googleAuth = async () => {
        try {
            const response = await signInWithPopup(auth, googleProvider)

            return response.user
        } catch (e) {
            return null
        }
    }

    const appleAuth = async () => {
        try {
            const response = await signInWithPopup(auth, appleProvider)

            return response.user
        } catch (e) {
            return null
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                dispatch(setUser(user))
            } else {
                dispatch(setUser(null))
            }

            const tokenRefreshInterval = 500000;
            const tokenRefreshTimer = setInterval(async () => {
                try {
                    const currentUser = auth.currentUser

                    if (currentUser) {
                        const tokenResult = await currentUser.getIdTokenResult();

                        if (tokenResult) {
                            const tokenExpirationTime = new Date(tokenResult.expirationTime).getTime();
                            const currentTime = new Date().getTime();

                            const timeToExpiration = tokenExpirationTime - currentTime;

                            if (timeToExpiration < tokenRefreshInterval) {
                                try {
                                    const newToken = await currentUser.getIdToken(true);

                                    dispatch(
                                        setUser({ ...currentUser, accessToken: newToken })
                                    )
                                } catch (error) {
                                    console.log('Error refreshing ID token:', error);
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.log('Error refreshing token:', error);
                }
            }, 200000);

            return () => {
                clearInterval(tokenRefreshTimer)
            }
        });
    }, [dispatch])

    const value = {
        googleAuth,
        signOutUser,
        appleAuth
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}