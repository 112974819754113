export const convertDateToLocalFormat = (date: string): string => {
    const d = new Date(date);

    return d.toLocaleString('uk-UA', {
        timeZone: 'Europe/Kiev',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    });
}