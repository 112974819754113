import { useEffect, useState } from "react"

import { useGetPaymentsListQuery } from "../api/appApi"
import usePagination from "../hooks/usePagination"
import { useAppSelector } from "../redux/hooks"

import Pagination from "./common/Pagination"
import EmptyTable from "./common/EmptyTable"
import Table from "./common/Table"
import Title from "./common/Title"

const Payments = () => {
    const [search, setSearch] = useState<string>("")
    const userInfo = useAppSelector((state) => state.app.userInfo)

    const {
        page,
        setPage,
        totalPages,
        setTotalPages,
        itemsPerPage,
    } = usePagination(20)

    const { data, isLoading } = useGetPaymentsListQuery(
        { page, itemsPerPage, search },
        { skip: !userInfo || userInfo.role === 'USER' }
    )

    useEffect(() => {
        if (data) {
            setTotalPages(Math.ceil(data.count / itemsPerPage))
        }
    }, [data, setTotalPages, itemsPerPage])

    return (
        <div className="flex flex-col p-6 gap-4">
            <Title
                title="Оплати"
                setPage={setPage}
                setSearch={setSearch}
            />
            {!isLoading && (
                <>
                    {data && data.results.length > 0 ? (
                        <Table
                            type={"payments"}
                            data={data.results}
                            columns={["address", "license_plate", "email", "amount", "payment_date", "status"]}
                            beforeValue={{
                                'amount': '₴'
                            }}
                        />
                    ) : (
                        <EmptyTable
                            isSearch={search.length > 0}
                            title="Поки немає оплат"
                        />
                    )}
                </>
            )}
            {data && data.results.length !== 0 && totalPages > 1 && (
                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            )}
        </div>
    )
}

export default Payments