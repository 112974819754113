import { IoMdClose } from "react-icons/io"
import { FC, useState } from "react"

import { useAddParkingMutation } from "../api/appApi";

import Modal from "./Modal"

type AddParkingModalProps = {
    onClose: () => void;
}

const AddParkingModal: FC<AddParkingModalProps> = ({ onClose }) => {
    const [monthlySubscription, setMonthlySubscription] = useState<number>(0)
    const [geolocation, setGeolocation] = useState<string>("")
    const [hourlyRate, setHourlyRate] = useState<number>(0)
    const [nightRate, setNightRate] = useState<number>(0)
    const [capacity, setCapacity] = useState<number>(0)
    const [address, setAddress] = useState<string>("")
    const [name, setName] = useState<string>("")

    const [addParking] = useAddParkingMutation()

    const handleAddParking = () => {
        if (name && address && capacity && geolocation && hourlyRate) {
            addParking({
                name,
                address,
                capacity,
                geolocation,
                hourly_rate: String(hourlyRate),
                night_rate: String(nightRate),
                month_subscription: String(monthlySubscription)
            })
            onClose()
        } else {
            alert('Заповніть всі поля!')
        }
    }

    return (
        <Modal bodyStyles={{ borderRadius: '12px' }} customBodyClasses="bg-textColor max-w-[500px]" onClose={onClose}>
            <div className="flex flex-col gap-4">
                <h5 className="text-[22px] leading-[28px] font-semibold flex items-center justify-between">
                    Додати парковку
                    <IoMdClose className="cursor-pointer" onClick={onClose} size={28} />
                </h5>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Назва парковки
                    </p>
                    <input
                        className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Назва парковки"
                        value={name}
                        type="text"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Адреса парковки
                    </p>
                    <input
                        className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Адреса парковки"
                        value={address}
                        type="text"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Місткість
                    </p>
                    <input
                        className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                        onChange={(e) => setCapacity(Number(e.target.value))}
                        placeholder="Місткість"
                        value={capacity}
                        type="number"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Геолокація
                    </p>
                    <input
                        className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                        onChange={(e) => setGeolocation(e.target.value)}
                        placeholder="50.7460686,25.3171832"
                        value={geolocation}
                        type="string"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Погодинна ставка
                    </p>
                    <input
                        className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                        onChange={(e) => setHourlyRate(Number(e.target.value))}
                        placeholder="10"
                        value={hourlyRate}
                        type="number"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Нічна ставка
                    </p>
                    <input
                        className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                        onChange={(e) => setNightRate(Number(e.target.value))}
                        placeholder="10"
                        value={nightRate}
                        type="number"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Підписка на місяць
                    </p>
                    <input
                        className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                        onChange={(e) => setMonthlySubscription(Number(e.target.value))}
                        placeholder="10"
                        value={monthlySubscription}
                        type="number"
                    />
                </div>
                <div className="flex items-center gap-2 mt-3">
                    <button onClick={handleAddParking} className="h-[56px] rounded-lg bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300 w-1/2 font-semibold text-[18px] leading-[26px]">
                        Додати
                    </button>
                    <button onClick={onClose} className="h-[56px] rounded-lg border-solid border-[1px] border-placeholderColor w-1/2 font-semibold text-[18px] leading-[26px]">
                        Скасувати
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default AddParkingModal