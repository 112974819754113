import { FC, useMemo } from "react";

import { useGetParkingsStatsQuery } from "../api/appApi";

import Table from "./common/Table";

type ParkingsStatsProps = {
    selected: string
}

const ParkingsStats: FC<ParkingsStatsProps> = ({ selected }) => {
    const { data } = useGetParkingsStatsQuery(selected)

    const dataWithIds = useMemo(() => {
        return data?.map((item, index) => {
            return {
                ...item,
                id: `${index + 1}`
            }
        })
    }, [data])

    return (
        <>
            {dataWithIds && dataWithIds.length > 0 && (
                <div className="flex flex-col gap-4">
                    <h5 className="text-[22px] leading-[28px] font-semibold">
                        Статистика парковок
                    </h5>
                    <div className="flex flex-col w-full">
                        {dataWithIds && dataWithIds.length > 0 && (
                            <Table
                                type="parkings_stats"
                                data={dataWithIds}
                                columns={["address", "total_parking_sessions", "total_earnings", "total_active_parking_sessions"]}
                                beforeValue={{
                                    'total_earnings': '₴'
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default ParkingsStats