import { RiAdminFill } from 'react-icons/ri'

import { useAuthContext } from '../contexts/AuthContext'

const NoPermissions = () => {
    const authCtx = useAuthContext()

    const handleSignOut = () => {
        authCtx?.signOutUser()
    }

    return (
        <div className="w-full min-h-screen bg-textColor flex items-center justify-center p-6">
            <div className='max-w-[650px] w-full flex flex-col gap-6 justify-center items-center'>
                <div className="w-[120px] h-[120px] rounded-full bg-buttonColor flex items-center justify-center">
                    <RiAdminFill size={60} />
                </div>
                <h5 className="text-[28px] leading-[36px] font-bold text-center">
                    Вибачте! У Вас немає доступу до панелі керування
                </h5>
                <p className="text-base font-semibold text-center">
                    Зверніться до головного адміністратора для отримання доступу або увійдіть під іншим акаунтом
                </p>
                <button
                    onClick={handleSignOut}
                    className='h-[50px] rounded-xl px-4 font-semibold bg-buttonColor text-base hover:bg-buttonHoverColor transition-colors duration-300'
                >
                    Вийти з акаунту
                </button>
            </div>
        </div>
    )
}

export default NoPermissions