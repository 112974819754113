import { FC, Fragment } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

type PaginationProps = {
    page: number;
    setPage: (page: number) => void;
    totalPages: number;
}

const Pagination: FC<PaginationProps> = ({ page, setPage, totalPages }) => {
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

    const handleChangePage = (pageNumber: number) => setPage(pageNumber);

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1)
        }
    }

    return (
        <div className="w-full flex justify-center items-center gap-2">
            <button onClick={handlePrevPage} disabled={page === 1 ? true : false} className="h-[40px] w-[40px] rounded-lg bg-backgroundColor text-textColor disabled:opacity-30 flex items-center justify-center text-base font-semibold cursor-pointer">
                <IoIosArrowBack size={20} />
            </button>
            <div className="w-fit flex items-center justify-center border-solid border-[1px] border-backgroundColor rounded-lg overflow-hidden">
                {pages.map((pageNumber) => {
                    if (totalPages > 7) {
                        if (pageNumber === 1) {
                            return (
                                <Fragment key={pageNumber}>
                                    <div onClick={() => handleChangePage(pageNumber)} className={`h-[40px] font-semibold text-[18px] leading-[26px] w-[40px] flex items-center justify-center border-e-[1px] border-solid border-backgroundColor last:border-0 cursor-pointer transition-colors ${page === pageNumber ? 'bg-backgroundColor text-textColor' : 'text-darkColor'}`}>
                                        {pageNumber}
                                    </div>
                                    {page > 3 && (
                                        <div className={`h-[40px] font-semibold text-[18px] leading-[26px] w-[40px] flex items-center justify-center border-e-[1px] border-solid border-backgroundColor last:border-0 cursor-pointer transition-colors text-darkColor`}>
                                            ...
                                        </div>
                                    )}
                                </Fragment>
                            )
                        } else if (pageNumber === totalPages) {
                            return (
                                <Fragment key={pageNumber}>
                                    {page < totalPages - 2 && (
                                        <div className={`h-[40px] font-semibold text-[18px] leading-[26px] w-[40px] flex items-center justify-center border-e-[1px] border-solid border-backgroundColor last:border-0 cursor-pointer transition-colors text-darkColor`}>
                                            ...
                                        </div>
                                    )}
                                    <div onClick={() => handleChangePage(pageNumber)} className={`h-[40px] font-semibold text-[18px] leading-[26px] w-[40px] flex items-center justify-center border-e-[1px] border-solid border-backgroundColor last:border-0 cursor-pointer transition-colors ${page === pageNumber ? 'bg-backgroundColor text-textColor' : 'text-darkColor'}`}>
                                        {pageNumber}
                                    </div>
                                </Fragment>
                            )
                        } else if (pageNumber === 1 || pageNumber === totalPages || (pageNumber >= page - 1 && pageNumber <= page + 1) || (page <= 3 && pageNumber <= 5) || (page > totalPages - 3 && pageNumber >= totalPages - 4)) {
                            return (
                                <div key={pageNumber} onClick={() => handleChangePage(pageNumber)} className={`h-[40px] font-semibold text-[18px] leading-[26px] w-[40px] flex items-center justify-center border-e-[1px] border-solid border-backgroundColor last:border-0 cursor-pointer transition-colors ${page === pageNumber ? 'bg-backgroundColor text-textColor' : 'text-darkColor'}`}>
                                    {pageNumber}
                                </div>
                            )
                        } else {
                            return null
                        }
                    } else {
                        return (
                            <div key={pageNumber} onClick={() => handleChangePage(pageNumber)} className={`h-[40px] font-semibold text-[18px] leading-[26px] w-[40px] flex items-center justify-center border-e-[1px] border-solid border-backgroundColor last:border-0 cursor-pointer transition-colors ${page === pageNumber ? 'bg-backgroundColor text-textColor' : 'text-darkColor'}`}>
                                {pageNumber}
                            </div>
                        )
                    }
                })}
            </div>
            <button onClick={handleNextPage} disabled={totalPages === page ? true : false} className="h-[40px] w-[40px] rounded-lg bg-backgroundColor text-textColor disabled:opacity-30 flex items-center justify-center text-base font-semibold cursor-pointer">
                <IoIosArrowForward size={20} />
            </button>
        </div>
    )
}

export default Pagination