import { IoMdClose } from "react-icons/io";
import { FaCheckSquare } from "react-icons/fa";
import { FC, useEffect, useState } from "react";

import { useEditUserMutation, useGetUserByIdQuery } from "../api/appApi";

import Modal from "./Modal"

export type EditUserModalProps = {
    onClose: () => void;
    id: string;
}

const EditUserModal: FC<EditUserModalProps> = ({ onClose, id }) => {
    const [role, setRole] = useState<"USER" | "MANAGER">("USER")
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [isActive, setIsActive] = useState<boolean>(true)

    const { data } = useGetUserByIdQuery(id)
    const [editUser] = useEditUserMutation()

    const handleEditUser = () => {
        editUser({ id, data: { phone_number: phoneNumber, role, is_active: isActive } })
        onClose()
    }

    useEffect(() => {
        if (data) {
            setPhoneNumber(data.phone_number)
            setIsActive(data.is_active)
            if (data.role === 'MANAGER' || data.role === 'USER') {
                setRole(data.role)
            }
        }
    }, [data])

    return (
        <Modal bodyStyles={{ borderRadius: '12px' }} customBodyClasses="bg-textColor max-w-[500px]" onClose={onClose}>
            <div className="flex flex-col gap-4">
                <h5 className="text-[22px] leading-[28px] font-semibold flex items-center justify-between">
                    Редагувати користувача
                    <IoMdClose className="cursor-pointer" onClick={onClose} size={28} />
                </h5>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Номер телефону
                    </p>
                    <input
                        className="h-[44px] border-solid border-[1px] border-borderColor rounded-lg px-4"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Номер телефону"
                        value={phoneNumber}
                        type="text"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-[16px] leading-[22px] text-darkText">
                        Роль користувача
                    </p>
                    {data?.role === 'ADMIN' ? (
                        <div className="flex items-center gap-2">
                            <div className={`h-[40px] flex items-center justify-center w-[120px] rounded-lg text-base font-medium cursor-pointer ${data?.role === 'ADMIN' ? 'border-solid border-[1px] border-buttonColor' : 'border-solid border-[1px] border-placeholderColor'}`}>
                                ADMIN
                            </div>
                        </div>
                    ) : (
                        <div className="flex items-center gap-2">
                            <div onClick={() => setRole("USER")} className={`h-[40px] flex items-center justify-center w-[120px] rounded-lg text-base font-medium cursor-pointer ${role === 'USER' ? 'border-solid border-[1px] border-buttonColor' : 'border-solid border-[1px] border-placeholderColor'}`}>
                                USER
                            </div>
                            <div onClick={() => setRole("MANAGER")} className={`h-[40px] flex items-center justify-center w-[120px] rounded-lg text-base font-medium cursor-pointer ${role === 'MANAGER' ? 'border-solid border-[1px] border-buttonColor' : 'border-solid border-[1px] border-placeholderColor'}`}>
                                MANAGER
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex items-center gap-2">
                    {isActive ? (
                        <FaCheckSquare
                            className="cursor-pointer"
                            color={"#4440c6"}
                            onClick={() => setIsActive(false)}
                            size={24}
                        />
                    ) : (
                        <div
                            onClick={() => setIsActive(true)}
                            className="w-[24px] h-[24px] rounded-[4px] border-solid border-[1px] border-placeholderColor"
                        ></div>
                    )}
                    <p className="text-[16px] leading-[22px] text-darkText font-medium">
                        Активний
                    </p>
                </div>
                <div className="flex items-center gap-2 mt-3">
                    <button onClick={handleEditUser} className="h-[56px] rounded-lg bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300 w-1/2 font-semibold text-[18px] leading-[26px]">
                        Зберегти зміни
                    </button>
                    <button onClick={onClose} className="h-[56px] rounded-lg border-solid border-[1px] border-placeholderColor w-1/2 font-semibold text-[18px] leading-[26px]">
                        Скасувати
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default EditUserModal