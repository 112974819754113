import { FC, useState } from 'react'

import { MODEL_TO_TEXT, SYSTEM_ACTION_TO_TEXT } from '../utils/constants'
import { convertDateToLocalFormat } from '../utils/functions'
import { TSystemEventLog } from '../utils/types'

type SystemEventItemProps = {
    event: TSystemEventLog
}

const SystemEventItem: FC<SystemEventItemProps> = ({ event }) => {
    const [showDetails, setShowDetails] = useState<boolean>(false)

    return (
        <div className='border-b-[1px] border-x-[1px] border-whiteButtonHoverColor last:rounded-b-xl flex flex-col'>
            <div onClick={() => setShowDetails(!showDetails)} className="grid w-full h-[50px] bg-dashboardTableColor items-center grid-cols-4 hover:bg-placeholderColor transition-colors cursor-pointer">
                <div className="py-2 px-4 text-textDark truncate">
                    {event.admin}
                </div>
                <div className="py-2 px-4 text-textDark truncate">
                    {SYSTEM_ACTION_TO_TEXT[event.action]}
                </div>
                <div className="py-2 px-4 text-textDark truncate">
                    {MODEL_TO_TEXT[event.model_name]}
                </div>
                <div className="py-2 px-4 text-textDark truncate">
                    {convertDateToLocalFormat(event.performed_at)}
                </div>
            </div>
            {showDetails && (
                <div className='border-solid border-t-[1px] border-whiteButtonHoverColor p-4 grid w-full grid-cols-2'>
                    <div className='flex flex-col gap-2'>
                        <h5 className='text-[18px] leading-[22px] font-semibold text-darkColor'>
                            Початкове значення
                        </h5>
                        {event.action === 'CREATE' ? (
                            <div className='flex items-center h-full w-full justify-center'>
                                <span className='font-semibold text-secondaryBackgroundColor'>
                                    Об'єкт відсутній
                                </span>
                            </div>
                        ) : (
                            <div>
                                {Object.keys(event.before).map((key, index) => (
                                    <div key={index} className='flex justify-between'>
                                        <span className='text-secondaryBackgroundColor text-[14px] leading-[17px] font-semibold'>
                                            {key}
                                        </span>
                                        <span className='text-darkColor text-[14px] leading-[17px] font-semibold'>
                                            {JSON.stringify(event.before[key])}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col ps-4 gap-2'>
                        <h5 className='text-[18px] leading-[22px] font-semibold text-darkColor'>
                            Змінене значення
                        </h5>
                        {event.action === 'DELETE' ? (
                            <div className='flex items-center h-full w-full justify-center'>
                                <span className='font-semibold text-secondaryBackgroundColor'>
                                    Об'єкт видалено
                                </span>
                            </div>
                        ) : (
                            <div>
                                {Object.keys(event.after).map((key, index) => (
                                    <div key={index} className='flex justify-between'>
                                        <span className='text-secondaryBackgroundColor text-[14px] leading-[17px] font-semibold'>
                                            {key}
                                        </span>
                                        <span className='text-darkColor text-[14px] leading-[17px] font-semibold'>
                                            {JSON.stringify(event.after[key])}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )
            }
        </div >
    )
}

export default SystemEventItem